<template>
  <div class="oci-map-body">
    <div class="oci-map-header">
      <div class="float-left">
        Site closes: {{ data.closetime }}
      </div>
      <div class="float-right">
        <feather-icon
          id="notifications"
          icon="XIcon"
          size="24"
          @click="closeMap"
        />
      </div>
    </div>

    <div
      ref="map"
      class="oci-map"
    />
    <CheckInOutButton
      :is-map="true"
      :latitude="currentPosition.lat"
      :longitude="currentPosition.lng"
      :accuracy="currentPosition.acc"
    />    
    <!--
    <b-row> 
    <b-col cols="6">
    <CheckInOutButton
      :is-map="true"
      :latitude="currentPosition.lat"
      :longitude="currentPosition.lng"
      :accuracy="currentPosition.acc"
    />
    </b-col>
    <b-col cols="6">
    <CancelSignedOutButton/>
    </b-col>
    </b-row>
    -->
  </div>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'
import gmapsInit from '@/components/map/gmaps'
import CheckInOutButton from '@/components/ui/CheckInOutButton.vue'
import CancelSignedOutButton from '@/components/ui/CancelSignedOutButton.vue'
// import { $themeConfig } from '@themeConfig'

export default {
  name: 'GoogleMapComponent',
  components: {
    CancelSignedOutButton,
    CheckInOutButton,
    BButton,
    BRow,
    BCol,
  },
  props: {
    site: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      checkingIn: true,
      user: null,
      map: null,
      watchPositionId: null,
      currentPosition: { lat: 0, lng: 0, acc: 0 },
      g: null,
      lat: 0,
      lng: 0,
      data: {
        closetime: '5:00 pm',
      },
    }
  },
  computed: {
  },
  async mounted() {
    this.g = await gmapsInit()
    await this.getCurrentPosition()
  },
  created() {
    // Set `appConfig` when page/SFC is created
    // this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true)

  },
  methods: {
    closeMap() {
      this.$router.go(-1)
    },
    displayLocationMap(position) {
      console.log('current position: ', position)
      console.log('Accuracy(meters): ', position.coords.accuracy)
      // const { latitude, longitude, accuracy } = position.coords;
      // console.log(`Latitude: ${latitude}, Longitude: ${longitude}, Accuracy: ${accuracy} meters`);

      this.currentPosition = { lat: position.coords.latitude, lng: position.coords.longitude, acc: position.coords.accuracy }
      // this.currentPosition = {lat:38.06004933020385, lng:-78.48965742825811};
      const destinationPosition = { lat: this.site.latitude, lng: this.site.longitude }
      this.map = new google.maps.Map(this.$refs.map, {
        center: this.currentPosition,
        zoom: 14,
        maxZoom: 20,
        minZoom: 3,
        streetViewControl: true,
        mapTypeControl: true,
        fullscreenControl: false,
        zoomControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      })
      
      // this.setMarker(this.currentPosition, 'O', '#2C74AF', '#49A7EA') // , 'https://files.promentum.co.uk/oci/operative.png'
      // this.setMarker(destinationPosition, 'X', '#6E6B7B', '#B5BF5B') // , 'https://files.promentum.co.uk/oci/site.png'

      this.setMarker(this.currentPosition, ' ', '#2C74AF', '#49A7EA', 'https://files.promentum.co.uk/oci/ic_round_man.svg') // , 'https://files.promentum.co.uk/oci/operative.png'
      this.setMarker(destinationPosition, ' ', '#6E6B7B', '#E65858', 'https://files.promentum.co.uk/oci/pointer_site.svg') // , 'https://files.promentum.co.uk/oci/site.png'

      const directionRequest = {
        origin: this.currentPosition,
        destination: destinationPosition,
        travelMode: 'DRIVING',
      }

      const directionService = new google.maps.DirectionsService()
      const directionRender = new google.maps.DirectionsRenderer()

      directionRender.setMap(this.map)

      directionService.route(directionRequest, (result, status) => {
        console.log('status: ',status)
        if (status === 'OK') {
          // directionRender.setDirections(result);
          // let steps = result.routes[0].legs[0].steps;
          // steps.forEach((res,key) => {
          //   this.setMarker({lat: res.start_location.lat(), lng: res.start_location.lng()}, 'END', '#FFF');
          // });
        }
      })

      // var marker = new google.maps.Marker({
      //   map: this.map
      // });

      // navigator.geolocation.watchPosition(
      //   position => {
      //     this.lat = position.coords.latitude;
      //     this.lng = position.coords.longitude;
      //     this.map.setCenter(new google.maps.LatLng(this.lat, this.lng));
      //     //marker.setPosition(new google.maps.LatLng(this.lat, this.lng));
      //     this.updateLocation(this.lat, this.lng);
      //     this.setMarker({lat: 38.05995037789053, lng: -78.48917801770385}, 'END', 'black');
      //   },
      //   error => {
      //     console.log(error.message);
      //   }
      // );
    },
    getCurrentPosition() {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.displayLocationMap(position)
        },
        error => {
          this.currentPosition.lat = 0
          this.currentPosition.lng = 0
          console.log('map position: ', error.message)
        },
      )
    },
    stopLocationUpdates() {
      navigator.geolocation.clearWatch(this.watchPositionId)
    },
    // updateLocation(lat, lng) {
    //   this.setMarker({lat: lat, lng: lng}, 'YOU', 'purple');
    // },
    setMarker(points, labelText, labelColor, markerColor, iconPath) {
      const iconSVG = {
        path: `M13.04,41.77c-0.11-1.29-0.35-3.2-0.99-5.42c-0.91-3.17-4.74-9.54-5.49-10.79c-3.64-6.1-5.46-9.21-5.45-12.07
          c0.03-4.57,2.77-7.72,3.21-8.22c0.52-0.58,4.12-4.47,9.8-4.17c4.73,0.24,7.67,3.23,8.45,4.07c0.47,0.51,3.22,3.61,3.31,8.11
          c0.06,3.01-1.89,6.26-5.78,12.77c-0.18,0.3-4.15,6.95-5.1,10.26c-0.64,2.24-0.89,4.17-1,5.48C13.68,41.78,13.36,41.78,13.04,41.77z
          `,
        fillColor: markerColor,
        fillOpacity: 2,
        strokeColor: labelColor,
        strokeWeight: 2,
        scale: 1,
        anchor: new google.maps.Point(14, 43),
        labelOrigin: new google.maps.Point(13.5, 15),
      }

      // const { mapLocationMarker } = $themeConfig.app
      const markers = new google.maps.Marker({
        position: points,
        map: this.map,
        animation: google.maps.Animation.DROP,
        label: {
          text: labelText,
          color: labelColor,
          fontSize: '11px',
        },
        icon: iconPath,
      })
      return iconSVG
    },
  },
}
</script>
<style>

</style>
