<template>
  <GoogleMapComponent :site="site" />
</template>

<script>
import { mapGetters } from 'vuex'
import GoogleMapComponent from '@/views/components/GoogleMap.vue'

export default {
  components: {
    GoogleMapComponent,
  },
  props: {
  },
  computed: {
    ...mapGetters({ site: 'app/getCurrentSiteDetails' }),
  },
}
</script>
